export const PageSlugs = {
  INDEX: 'index',
  OSRS: 'Buy-OSRS-Gold',
  RS3: 'buy-rs3-gold',
  OSRS_ITEMS: 'osrs-items',
  BUY_MEMBERSHIP: 'buy-rs-membership',
  SELL_GOLD: 'sell-rs-gold',
  ACCOUNTS: 'buy-osrs-account',
  ACCOUNT_RENTAL: 'osrs-max-main-rentals',
  BOOSTING: 'boosting',
  BUY_POINTS: 'buy-points',
  CUSTOM_PAYMENT: 'custom-payment',
  CONTACTS: 'contacts',
  LOYALTY_PROGRAM_ABOUT: 'loyalty-program',
  LOYALTY_REWARDS_STORE: 'loyalty-rewards',
  BLOG: 'blog',
  FEEDBACK: 'feedback',
  DEMO: 'demo',
  ACCOUNT_ORDERS: 'account/orders',
  ACCOUNT_REWARDS: 'account/rewards',
  ACCOUNT_LOYALTY: 'account/loyalty',
  ACCOUNT_ACTIVATE: 'account/activate',
  ACCOUNT_SETTINGS: 'account/settings',
  PASSWORD_RESET: 'password/reset',
  CHECKOUT: 'checkout',
  TERMS: 'terms',
  SITEMAP: 'sitemap.xml'
};
