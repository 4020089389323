import {extendedTypography} from '../extendedTypography';

export const tooltip = theme => ({
  MuiTooltip: {
    tooltip: {
      backgroundColor: theme.palette.backgrounds.tooltip,
      padding: theme.spacing(1.5, 1.5),
      ...extendedTypography.caption1Desktop,

      [theme.breakpoints.down('sm')]: {
        ...extendedTypography.caption1RegularMobile
      }
    },
    touch: {
      backgroundColor: theme.palette.backgrounds.tooltip,
      padding: theme.spacing(1, 1.5),
      ...extendedTypography.caption1Desktop,

      [theme.breakpoints.down('sm')]: {
        ...extendedTypography.caption1RegularMobile
      }
    },
    tooltipPlacementBottom: {
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1, 0)
      }
    },
    arrow: {color: theme.palette.backgrounds.tooltip}
  }
});
