import cookie from 'js-cookie';
import {AUTH_TOKEN, JWT_EXP, REFRESH_TOKEN} from '../config';

const domain = process.env.COOKIE_DOMAIN;
const isProd = process.env.NODE_ENV === 'production';
const debug = process.env.DEBUG;
const userDetailsKey = 'userDetails';

export const loadUserFromStorage = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  try {
    return JSON.parse(localStorage.getItem(userDetailsKey));
  } catch (e) {
    console.log('Error on user loading from localStorage: ');
  }
  return undefined;
};

export const saveRefreshToken = token => {
  cookie.set(REFRESH_TOKEN, token, {
    expires: JWT_EXP,
    secure: isProd,
    sameSite: 'strict',
    domain
  });
};

export const saveAccessToken = token => {
  // document.cookie =
  //   'secureCookie=John; path=/; max-age=604800; samesite=lax; secure';
  // document.cookie =
  //   'secureCookieDomain=John; path=/; max-age=604800; samesite=lax; secure; domain=.probemas.com';
  cookie.set(AUTH_TOKEN, token, {
    expires: JWT_EXP, // iOS Safari allow only 7 days for cookies
    secure: isProd,
    sameSite: 'lax', // `strict` produces errors on iOS
    path: '/',
    domain
  });
};

export const saveCookie = (name, value) => {
  cookie.set(name, value, {
    expires: 30,
    secure: isProd,
    sameSite: 'lax', // `strict` produces errors on iOS
    path: '/',
    domain
  });
};

export const saveUserToStorage = (user, token, refreshToken) => {
  if (token) saveAccessToken(token);
  if (refreshToken) saveRefreshToken(refreshToken);
  localStorage.setItem(userDetailsKey, JSON.stringify(user));
};

export const removeUserFromStorage = () => {
  cookie.remove(AUTH_TOKEN, {domain});
  cookie.remove(REFRESH_TOKEN, {domain});
  localStorage.removeItem(userDetailsKey);
};
