import {alpha} from '@material-ui/core/styles';

import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

export const inputs = theme => ({
  // MuiFilledInput: {
  //   input: {
  //     paddingTop: 14,
  //     paddingBottom: 13
  //   },
  //   inputMarginDense: {
  //     paddingTop: 9,
  //     paddingBottom: 8
  //   }
  // },
  // MuiFormHelperText: {
  //   contained: {
  //     marginLeft: 0,
  //     marginRight: 0
  //   }
  // }
  MuiOutlinedInput: {
    // <--------secondary color variant - light style ------>
    colorSecondary: {
      backgroundColor: 'transparent',
      color: theme.palette.colors.blue[400],

      // <------ GOLD CARD INPUT ------>
      '&.gold': {
        backgroundColor: 'rgba(255, 255, 255, 0.20)',
        color: theme.palette.common.white,

        '&.inactive': {
          pointerEvents: 'none'
        },

        '&.inactive:hover, &.inactive:hover fieldset': {
          borderColor: 'transparent !important'
        },

        '&:hover': {
          borderColor: [theme.palette.colors.yellow[600], '!important']
        },

        // secondary color variant - light style
        '& fieldset': {
          // color: theme.palette.colors.blue[400],
          borderWidth: 1,
          borderColor: 'transparent',
          transition: 'all .15s',

          '&.Mui-focused': {
            transition: 'all .15s',
            borderColor: theme.palette.colors.yellow[600]
          },

          '& .Mui-error': {
            borderWidth: '1.5px !important'
          }
        },

        '&.Mui-focused': {
          color: theme.palette.common.white
        },

        '&:hover fieldset': {
          borderWidth: '1px !important',
          borderColor: [theme.palette.colors.yellow[600], '!important'],
          transition: 'all .15s'
        },

        '&.Mui-focused fieldset, &&:hover.Mui-focused fieldset': {
          borderWidth: '1px !important',
          borderColor: [theme.palette.colors.yellow[600], '!important'],
          transition: 'all .15s'
        }
      },
      // <----- END GOLD CARD INPUT ---->

      '&.Mui-focused': {
        color: theme.palette.colors.blue[800]
      },

      '& fieldset': {
        // color: theme.palette.colors.blue[400],
        borderWidth: 1,
        borderColor: theme.palette.colors.blue[100],
        transition: 'all .15s',

        '&.Mui-focused': {
          transition: 'all .15s',
          borderColor: theme.palette.colors.inputOutlinedActive
        },

        '& .Mui-error': {
          borderWidth: '1.5px !important'
        }
      },

      '& svg': {
        width: 24,
        height: 24
      },

      inputAdornedStart: {},
      inputAdornedEnd: {},

      '&.Mui-disabled': {
        color: alpha(theme.palette.colors.blue[800], 0.38)
        // backgroundColor: theme.palette.colors.grey
      },

      '&.Mui-error': {
        borderWidth: '1.5px !important'
      },

      '&.Mui-focused fieldset, &&:hover.Mui-focused fieldset': {
        borderWidth: '1.5px !important',
        borderColor: [theme.palette.colors.lightBlue[600], '!important'],
        transition: 'all .15s'
      },

      '&:hover fieldset': {
        borderWidth: '1px !important',
        borderColor: [theme.palette.colors.blue[200], '!important'],
        transition: 'all .15s'
      },

      '&:hover.Mui-disabled fieldset, &.Mui-disabled fieldset': {
        borderWidth: '1px !important',
        border: `1px solid ${theme.palette.colors.blue[200]} !important`,
        // background: 'rgba(0, 0, 0, 0.05)'
        background: alpha(theme.palette.common.black, 0.15)
      },

      '&:hover.Mui-error fieldset, &.Mui-focused.Mui-error fieldset, &:hover.Mui-focused.Mui-error fieldset, &.Mui-error fieldset':
        {
          borderWidth: '1.5px !important',
          borderColor: [theme.palette.error.light, '!important'],
          transition: 'all .15s'
        }
    },
    // <--------end secondary color variant - light style ------>
    marginDense: {
      height: 40,

      '& svg': {
        width: 20,
        height: 20
      }
    },

    notchedOutline: {
      // borderWidth: 1,
      // borderColor: theme.palette.colors.inputOutlined,
      // transition: 'all 2s',
      //
      // '&.Mui-focused': {
      //   transition: 'all 2s',
      //   borderColor: theme.palette.colors.inputOutlinedActive
      // },
      //
      // '& .Mui-error': {
      //   borderWidth: '1.5px !important'
      // }
    },

    root: {
      // primary color variant - dark style
      '& fieldset': {
        borderWidth: 1,
        borderColor: theme.palette.colors.inputOutlined,
        transition: 'all .15s',

        '&.Mui-focused': {
          transition: 'all .15s',
          borderColor: theme.palette.colors.inputOutlinedActive
        },

        '& .Mui-error': {
          borderWidth: '1.5px !important'
        }
      },

      '& svg': {
        width: 24,
        height: 24
      },

      inputAdornedStart: {},
      inputAdornedEnd: {},

      '&.Mui-disabled': {
        color: alpha(theme.palette.colors.inputDisabled, 0.38)
      },

      '&.Mui-error': {
        borderWidth: '1.5px !important'
      },

      '&.Mui-focused fieldset, &&:hover.Mui-focused fieldset': {
        borderWidth: '1.5px !important',
        borderColor: [theme.palette.colors.inputOutlinedActive, '!important'],
        transition: 'all .15s'
      },

      '&:hover fieldset': {
        borderWidth: '1px !important',
        borderColor: [theme.palette.colors.inputOutlinedHover, '!important'],
        transition: 'all .15s'
      },

      '&:hover.Mui-disabled fieldset, &.Mui-disabled fieldset': {
        borderWidth: '1px !important',
        border: '1px solid rgba(65, 77, 101, 0.50) !important',
        background: 'rgba(0, 0, 0, 0.05)'
      },

      '&:hover.Mui-error fieldset, &.Mui-focused.Mui-error fieldset, &:hover.Mui-focused.Mui-error fieldset, &.Mui-error fieldset':
        {
          borderWidth: '1.5px !important',
          borderColor: [theme.palette.error.light, '!important'],
          transition: 'all .15s'
        },

      height: 48
    },

    input: {
      paddingTop: 9,
      paddingBottom: 9,
      ...extendedTypography.body1RegularDesktop
    },

    inputMarginDense: {
      paddingTop: 9,
      paddingBottom: 9,
      ...extendedTypography.body1RegularMobile,
      lineHeight: 1.2
    }
  },

  MuiInputLabel: {
    shrink: {
      '& .Mui-error': {
        color: theme.palette.error.light
      }
    },
    outlined: {
      transform: 'translate(14px, 16px) scale(1)',

      '&.shrink.marginDense': {
        transform: 'translate(14px, -8px) scale(0.75)'
      },

      '&.marginDense': {
        transform: 'translate(14px, 10px) scale(1)'
      },

      // '&.focused': {
      //   transform: 'translate(14px, -8px) scale(0.75)'
      // },

      '&.Mui-focused': {
        color: [theme.palette.colors.inputOutlinedActive, '!important']
      },

      '&.Mui-focused.Mui-error': {
        color: [theme.palette.error.light, '!important']
      },

      '&.Mui-disabled': {
        color: alpha(theme.palette.colors.inputDisabled, 0.38)
      }
    },
    marginDense: {
      ...extendedTypography.body1RegularMobile
      // lineHeight: 1.2,
      // transform: 'translate(14px, 20px) scale(1)'
    }
  },
  PrivateNotchedOutline: {
    legendLabelled: {
      height: 14,
      // fontSize: '0.7em',

      [theme.breakpoints.down('xs')]: {
        fontSize: 10.5
      }
    }
  },
  MuiFormHelperText: {
    root: {
      ...extendedTypography.caption2RegularDesktop,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0)
    },
    marginDense: {
      ...extendedTypography.caption2RegularMobile,
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0)
    },
    contained: {
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0)
    }
  },
  MuiFormLabel: {
    colorSecondary: {
      color: theme.palette.colors.blue[400],

      '&.Mui-error': {
        color: theme.palette.colors.blue[400]
      },
      '&.Mui-disabled': {
        color: [theme.palette.colors.blue[200], '!important']
      }
    },
    root: {
      color: theme.palette.text.primary,

      [theme.breakpoints.down('sm')]: {
        // ...extendedTypography.body1RegularMobile,
        // fontSize: '2em'
      },

      '&.Mui-error': {
        color: theme.palette.common.white
      },
      '&.Mui-error[data-shrink="true"]': {
        color: theme.palette.error.light
      }
    }
  },
  MuiFormControlLabel: {
    root: {
      marginLeft: 0
    }
  },
  MuiCheckbox: {
    root: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0
    }
  }
});
