import {Base64} from 'js-base64';
import * as Sentry from '@sentry/browser';

import {CheckoutQuery} from 'src/views/CheckoutNext/queries';

import {CHECKOUT_KEY} from '../config';
import {ProductTypeEnum} from './enums';
import {
  ACTIVE_COUNTRY,
  ACTIVE_PAYMENT_METHOD
} from '../components/PaymentMethods';
export const MINUTE = 60;
export const HOUR = 60 * 60;
export const DAY = 60 * 60 * 24;

export const slugify = text =>
  text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-'); // Replace multiple - with single -

export const getDBIdFromGraphqlId = (graphqlId, schema) => {
  if (!graphqlId) {
    return;
  }

  // This is temporary solution, we will use slugs in the future
  const rawId = Base64.decode(graphqlId);
  const regexp = /(\w+):(\d+)/;
  const [, expectedSchema, id] = regexp.exec(rawId);

  if (schema && schema !== expectedSchema) {
    throw new Error('Schema is not correct');
  }

  return parseInt(id, 10);
};

export const getGraphqlIdFromDBId = (id, schema) =>
  // This is temporary solution, we will use slugs in the future
  Base64.encode(`${schema}:${id}`);

export const priceToString = (price, locale) => {
  const {amount} = price;

  if (locale) {
    return amount.toLocaleString(locale, {
      currency: price.currency,
      style: 'currency'
    });
  }

  return `${price.currency} ${amount.toFixed(2)}`;
};

export const maybe = (exp, d) => {
  try {
    const result = exp();
    return result === undefined ? d : result;
  } catch {
    return d;
  }
};

export const removeEmptySpaces = text => text.replace(/\s+/g, '');

export const enumToString = text =>
  text
    .toLowerCase()
    .replace(/^\w/, c => c.toUpperCase())
    .replace(/_/g, ' ');

export const find = (arr, key, value, isEdge = false, d = []) => {
  let result;

  if (isEdge) {
    const res = arr.find(item => item.node[key] === value);
    result = maybe(() => res.node, null);
  } else {
    result = arr.find(item => item[key] === value);
  }

  return result || d;
};

export const generateUrl = () => '';

export const resolve = (path, obj) => {
  try {
    return path
      .split('.')
      .reduce((prev, curr) => (prev ? prev[curr] : null), obj);
  } catch (e) {
    return null;
  }
};

export const parseJsonString = (obj, key) => {
  // console.log(obj[key] instanceof String);
  if (!!obj && (typeof obj[key] === 'string' || obj[key] instanceof String)) {
    obj[key] = JSON.parse(obj[key]);
  }

  return obj;
};

export const saveRsnInStorage = (rsn, category, type) => {
  let prevData = {};

  try {
    prevData = JSON.parse(localStorage.getItem('rsn'))[type];
  } catch (e) {}

  const newData = {
    [type]: {
      ...prevData,
      [category]: rsn
    }
  };
  const data = JSON.stringify(newData);
  localStorage.setItem('rsn', data);
};

export const getRsnFromStorage = (category, type) => {
  let data;

  try {
    data = localStorage.getItem('rsn');
    return JSON.parse(data)[type][category];
  } catch (e) {
    return '';
  }
};

export const saveCountryToStorage = method => {
  const data = JSON.stringify(method);
  localStorage.setItem(ACTIVE_COUNTRY, data);
};

export const getCountryFromStorage = (defaultCountry = null) => {
  let data;

  try {
    data = localStorage.getItem(ACTIVE_COUNTRY);
    return JSON.parse(data);
  } catch (e) {
    return defaultCountry;
  }
};

export const savePaymentMethodStorage = method => {
  const data = JSON.stringify(method);
  localStorage.setItem(ACTIVE_PAYMENT_METHOD, data);
};

export const getActivePaymentMethodFromStorage = () => {
  let data;

  try {
    data = localStorage.getItem(ACTIVE_PAYMENT_METHOD);
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

export const saveCheckoutDataInStorage = (checkout, client) => {
  // see CheckoutDetails.js for more details
  const newData = {
    id: checkout.id,
    token: checkout.token
    // type: checkout.pr
  };
  const data = JSON.stringify(newData);
  localStorage.setItem(CHECKOUT_KEY, data);

  if (client) {
    client.cache.writeQuery({
      query: CheckoutQuery,
      variables: {token: checkout.token},
      data: {checkout}
    });
  }
};

export const prepareCheckoutData = (checkout, activeMethod) => ({
  id: maybe(() => checkout.id, null),
  user: maybe(() => checkout.user, null),
  note: maybe(() => JSON.parse(checkout.extraData).note, null),
  baseTotal: maybe(() => checkout.basetotal.localized, null),
  baseTotalAmount: maybe(() => checkout.basetotal.amount, null),
  totalPrice: maybe(() => checkout.total.localized, null),
  totalPriceAmount: maybe(() => checkout.total.amount, null),
  subTotalPrice: maybe(() => checkout.subtotal.localized, null),
  subTotalPriceAmount: maybe(() => checkout.subtotal.amount, null),
  discountName: maybe(() => checkout.discountName, null),
  discountAmount: maybe(() => checkout.discountAmount, null),
  // applicable for payment methods discount
  methodDiscount: maybe(() => `-${checkout.methodDiscount.localized}`, null),
  methodFee: maybe(() => checkout.methodFee.localized, null),
  methodFeeAmount: maybe(() => checkout.methodFee.amount, null),
  voucherDiscount: maybe(() => checkout.voucherDiscount, null),
  // methodFeeLabel: maybe(
  //   () => checkout.methodDiscount.localized.replace(/-/g, ''),
  //   null
  // ),
  methodDiscountPercentLabel: maybe(() => checkout.method.discountAmount, null),
  discountLabel: maybe(
    () => checkout.discount.localized.replace(/^-/g, ''),
    null
  ),
  discountSign: maybe(
    () => (checkout.discount.amount > 0 ? 'off' : 'fee'),
    null
  ),
  // --------
  // discount: maybe(
  //   () =>
  //     checkout.discount && checkout.discount.amount
  //       ? checkout.discount.localized
  //       : null,
  //   null
  // ),
  discount: maybe(() => `-${checkout.discount.localized}`, null),
  voucherCode: maybe(() => checkout.voucherCode, null),
  extraGoldAmount: maybe(() => checkout.extraGoldAmount, null),
  extraLoyaltyPointsAmount: maybe(
    () => checkout.extraLoyaltyPointsAmount,
    null
  ),
  selectedMethodFee: maybe(() => checkout.selectedMethodFee.localized, null),
  selectedMethodFeeAmount: maybe(() => checkout.selectedMethodFee.amount, null),
  extraData: maybe(() => checkout.extraData, null),
  fullDescription: maybe(() => checkout.fullDescription, null),
  loyaltyPoints: maybe(() => checkout.loyaltyPoints, null),
  email: maybe(() => checkout.email, null),
  rsn: maybe(() => checkout.rsn, null),
  paymentMethod: maybe(() => activeMethod.label, null),
  description: maybe(() => checkout.description, null),
  totalQuantity: maybe(() => checkout.totalQuantity, null),
  quantity: maybe(() => checkout.quantity, null),
  productName: maybe(() => checkout.productName, null),
  productsQuantity: maybe(() => checkout.productsQuantity, null),
  provider: maybe(() => checkout.provider, null),
  type: maybe(() => checkout.type, null),
  isAutoVoucher: maybe(() => checkout.isAutoVoucher, false)
});

export const prepareOrderData = order => ({
  id: maybe(() => order.id, null),
  number: maybe(() => order.number, null),
  user: maybe(() => order.user, null),
  note: maybe(() => order.note, null),
  totalPrice: maybe(() => order.payment.total.localized, null),
  subTotalPrice: maybe(() => order.payment.subtotal.localized, null),
  subTotalPriceAmount: maybe(() => order.payment.subtotal.amount, null),
  methodFee: maybe(() => order.payment.methodFee.localized, null),
  loyaltyPoints: maybe(() => order.loyaltyPoints, null),
  extraLoyaltyPointsAmount: maybe(() => order.extraLoyaltyPointsAmount, null),
  extraData: maybe(() => order.extraData, null),
  email: maybe(() => order.userEmail, null),
  rsn: maybe(() => order.rsn, null),
  paymentMethod: maybe(() => order.payment.method.label, null),
  payment: maybe(() => order.payment, null),
  description: maybe(() => order.description, null),
  provider: maybe(() => order.provider, null),
  type: maybe(() => order.type, null),
  products: maybe(() => order.products, null)
});

export const roundTo = (number, precision) => {
  const divider = 10 ** precision;
  return Math.round(number * divider) / divider;
};

export const isAllowedToUser = (item, user) => {
  if (item.loyaltyLevel && item.loyaltyLevel.pointsPerUsd) {
    const userPointsPerUsd = maybe(
      () => user.loyaltyDetails.currentLevel.pointsPerUsd,
      0
    );
    return item.loyaltyLevel.pointsPerUsd <= userPointsPerUsd;
  }

  return true;
};

export const getPrice = (currency, priceInCurrencies) => {
  const price = priceInCurrencies.find(
    priceItem => priceItem.currency === currency
  );

  if (!price) {
    return null;
  }

  return price;
};

export const getProductTypePaths = () => {
  const paths = [];
  Object.values(ProductTypeEnum).map(productType =>
    paths.push({params: {productType: productType.toLowerCase()}})
  );
  return paths;
};

export const cleanupInput = str => str.replace(/[^a-zA-Z0-9 -]/g, '');

export const imageCoefficient = {
  mobile: 1.75,
  tablet: 1.5,
  desktop: 1
};

export function handleSentryError(err) {
  const error = JSON.stringify(err);
  return Sentry.captureMessage(error), Sentry.flush(2000);
}
