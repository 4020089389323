import {getDBIdFromGraphqlId, getGraphqlIdFromDBId} from './utils';

export const ADD_TO_CART = 'add_to_cart';
export const BEGIN_CHECKOUT = 'begin_checkout';
export const PURCHASE = 'purchase';
export const REGISTRATION = 'registration';
export const PAGE_VIEW = 'page_view';

export const prepareBaseEventDetails = (eventName, user = null) => ({
  event: eventName,
  clientType: user ? 'Registered Client' : 'New Client', // New or registered client
  loyaltyTier: user?.loyaltyDetails.currentLevel.name, // The loyalty tear of the user, if user is logged in
  loyaltyPoints: user?.loyaltyDetails?.points, // Amount of loyalty points available, if logged in
  userID: getDBIdFromGraphqlId(user?.id), // User ID from the database
  user_data: {
    id: getDBIdFromGraphqlId(user?.id), // User ID from the database
    email: user?.email // User email from the database
  }
});

const getEntityItems = entity => {
  let items = [];

  if (entity.products !== '{}') {
    const products = JSON.parse(entity.products);

    for (const [productId, product] of Object.entries(products)) {
      items.push({
        item_name: product.product_name,
        item_id: productId,
        item_category: product.type,
        quantity: product.quantity,
        price: (
          parseFloat(product.price_usd) / parseFloat(product.quantity)
        ).toFixed(2)
      });
    }
  } else {
    items = [
      {
        item_name: entity.productName, // Product name for RS3Gold.
        item_id: entity.productId, // Required. ID of the product/gold type
        price: entity.payment?.total?.amount
          ? (entity.payment?.total.amount / entity.quantity).toFixed(2)
          : (entity.total.amount / entity.quantity).toFixed(2), // Current price of gold
        item_category: entity.productType, // Gold, Accounts or loyalty points
        quantity: entity.quantity
      }
    ];
  }

  // console.log('items', items);

  return items;
};

const getProductsItems = products => {
  const items = [];

  for (const [productId, product] of Object.entries(products)) {
    items.push({
      item_name: product.name,
      item_id: getDBIdFromGraphqlId(product.id),
      item_category: product.gameVersion,
      quantity: 1,
      price: product.sellPrice.amount
    });
  }

  return items;
};

export const purchaseEvent = (order, pushDataLayer, user = null) => {
  const data = {
    ...prepareBaseEventDetails(PURCHASE, user),
    ecommerce: {
      transactionId: order.number,
      currency: order.payment.total.currency, // Selected currency
      coupon: order.voucher?.code,
      value: order.payment.total.amount,
      paymentMethod: order.payment.method.label,
      items: getEntityItems(order)
    }
  };
  pushDataLayer(data);
};

export const checkoutEvent = (checkout, pushDataLayer, user = null) => {
  const fields = {
    token: checkout.token,
    description: checkout.description,
    quantity: checkout.quantity,
    email: checkout.email
  };

  if (checkout.rsn) {
    fields.rsn = checkout.rsn;
  }

  const data = {
    ...prepareBaseEventDetails(BEGIN_CHECKOUT, user),
    id: getGraphqlIdFromDBId(JSON.stringify(fields)),
    ecommerce: {
      currency: checkout.currency, // Selected currency
      value: checkout.total.amount,
      items: getEntityItems(checkout)
    }
  };
  pushDataLayer(data);
};

export const addToCartEvent = (checkout, pushDataLayer, user = null) => {
  const fields = {
    token: checkout.token,
    description: checkout.description,
    quantity: checkout.quantity,
    email: checkout.email
  };

  if (checkout.rsn) {
    fields.rsn = checkout.rsn;
  }

  const data = {
    ...prepareBaseEventDetails(ADD_TO_CART, user),
    id: getGraphqlIdFromDBId(JSON.stringify(fields)),
    ecommerce: {
      currency: checkout.currency, // Selected currency
      value: checkout.total.amount,
      items: getEntityItems(checkout)
    }
  };
  pushDataLayer(data);
};

export const homepageViewEvent = (products, pushDataLayer, user = null) => {
  const data = {
    ...prepareBaseEventDetails(PAGE_VIEW, user),
    ecommerce: {
      items: getProductsItems(products)
    }
  };
  pushDataLayer(data);
};

export const registerEvent = (pushDataLayer, email) => {
  const data = {event: REGISTRATION, id: email};
  pushDataLayer(data);
};
